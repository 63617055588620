import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router";
import {useGetCategoriesQuery, useGetUserQuery, useUpdateUserMutation} from "../../../store/reducers/adminApi";
import Image from "react-bootstrap/Image";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import moment from "moment";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import ToggleButton from "react-bootstrap/ToggleButton";
import Form from "react-bootstrap/Form";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Select, {MultiValue} from 'react-select'
import makeAnimated from 'react-select/animated';
import {constants} from "../../../config/constants";
import ToastBar, {ToastData} from "../../../components/ToastBar/toastbar";
import chatSession from "../../../../../src/routes/chatSession";

export function Psychic() {

    const tags = useMemo(() => {
        return Object.keys(constants.UserTags).map((key, id) => ({
            id,
            label: key,
            value: constants.UserTags[key]
        }))
    }, []);

    let {userID} = useParams();
    const [currentTag, setCurrentTag] = useState("standard")
    const [allChatSessions, setAllChatSessions] = useState({});
    const [show, setShow] = useState(false);
    const [ToastMessage, setToastMessage] = useState<ToastData>({
        title: null,
        body: null,
        type: null
    })
    const [selectedCategories, setSelectedCategories] = React.useState<MultiValue<{
        value: string,
        label: string
    }>>([]);
    const animatedComponents = makeAnimated();

    const {data: user, refetch} = useGetUserQuery(userID, {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    });

    const {data: categories} = useGetCategoriesQuery(null)
    const [updateUser, updateUserResponse] = useUpdateUserMutation();
    const [payoutRate, setPayoutRate] = useState<any>(0);

    const setEnabled = () => {
        updateUser({userID: user.userID, enabled: !user.enabled}).unwrap().then(() => {
            refetch()
            showToast('Action', 'Updated Successfully', 'success')
        }).catch((e) => {
            showToast('Error', e.message, 'danger')
        });
    };

    const savePayoutRate = () => {
        updateUser({userID: user.userID, payout_rate: payoutRate}).unwrap().then(() => {
            refetch()
            showToast('Payout Rate', 'Updated Successfully', 'success')
        }).catch((e) => [
            showToast('Error', e.message, 'danger')
        ])
    };
    const updateCategories = () => {
        updateUser({userID: user.userID, userCategories: selectedCategories.map(c => c.value)}).unwrap().then(() => {
            refetch()
            showToast('Category', 'Updated Successfully', 'success')
        }).catch((e) => {
            showToast('Error', e.message, 'danger')
        });
    };
    useEffect(() => {
        setPayoutRate(prevValue => user ? user.payout_rate : prevValue)
        setCurrentTag(prevValue => user ? user.tag : prevValue)
        setSelectedCategories(prevValue => user ? user.userCategories.map(c => ({
            value: c.category.id,
            label: c.category.name
        })) : prevValue)
    }, [user]);

    const setUserTag = (e) => {
        updateUser({userID: user.userID, tag: e.target.value}).unwrap().then(() => {
            refetch()
            showToast('Pychic Account', 'Updated Successfully', 'success')
        }).catch((e) => {
            showToast('Error', e.message, 'danger')
        });
    }
    const options = useMemo(() => {
        return categories?.map(category => ({value: category.id, label: category.name})) || []
    }, [categories])

    const showToast = (title: string, body: string, type: 'success' | 'danger' | null) => {
        setToastMessage({
            title,
            body,
            type
        })
        setShow(true)
    }

    // let chatSessions = {};
    //
    // user?.transfers?.forEach(transfer => {
    //     if (transfer.chatSession) {
    //         chatSessions[transfer.chatSession.chatSessionID] = transfer.chatSession;
    //     }
    // });
    useEffect(() => {
        // First handle existing ones from transfers (current implementation)
        let transferChatSessions = {};
        if (user?.transfers) {
            user.transfers.forEach(transfer => {
                if (transfer.chatSession) {
                    transferChatSessions[transfer.chatSession.chatSessionID] = transfer.chatSession;
                }
            });
        }

        // Now handle chat sessions from the user's chats
        let chatSessionsFromChats = {...transferChatSessions};
        if (user?.chats) {
            user.chats.forEach(chat => {
                if (chat.chatSessions) {
                    chat.chatSessions.forEach(chatSession => {
                        chatSessionsFromChats[chatSession.chatSessionID] = chatSession;
                    });
                }
            });
        }

        setAllChatSessions(chatSessionsFromChats);
    }, [user]);

    return (
        <div>
            {user && (
                <React.Fragment>
                    <div className="d-flex flex-row align-items-center w-75 justify-content-between">
                        <Image roundedCircle src={user.avatar} width={150} height={150}/>
                        <ButtonGroup onChange={setUserTag}>
                            {tags.map((tag) => (
                                <ToggleButton
                                    className="btn-sm"
                                    id={`radio-${tag.id}`}
                                    type="radio"
                                    variant="outline-dark"
                                    checked={currentTag == tag.value}
                                    value={tag.value}
                                    onChange={(e) => setCurrentTag(e.currentTarget.value)}
                                >{tag.label}</ToggleButton>
                            ))}

                        </ButtonGroup>
                    </div>

                    <ToastBar tostData={ToastMessage} setToastData={setToastMessage}/>

                    <Table>
                        <tbody>
                        <tr>
                            <td>Status</td>
                            <td>
                                <Badge bg={user.enabled ? "success" : "danger"}>
                                    {user.enabled ? "Enabled" : "Disabled"}
                                </Badge>
                            </td>
                        </tr>
                        <tr>
                            <td>Stripe Setup</td>
                            <td>
                                <Badge bg={user.stripe_active ? "success" : "danger"}>
                                    {user.stripe_active ? "Complete" : "Incomplete"}
                                </Badge>
                            </td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>{user.email}</td>
                        </tr>
                        <tr>
                            <td>Username</td>
                            <td>{user.username}</td>
                        </tr>
                        <tr>
                            <td>Created On</td>
                            <td>{moment(user.created_at).format("MM-DD-YYYY HH:MM:SS")}</td>
                        </tr>
                        <tr>
                            <td>Bio</td>
                            <td>{user.bio}</td>
                        </tr>
                        <tr>
                            <td>Categories</td>
                            <td className="col-md-6">
                                <Select
                                    onChange={setSelectedCategories}
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    value={selectedCategories}
                                    defaultValue={selectedCategories}
                                    isMulti
                                    options={options}
                                />
                            </td>
                            <td>
                                <Button variant={"success"} onClick={updateCategories}>
                                    Save
                                </Button>
                            </td>
                        </tr>

                        <tr>
                            <td>Payout Rate</td>
                            <td>
                                <Form.Control
                                    value={payoutRate}
                                    onChange={(e) => setPayoutRate(e.target.value)}
                                    type="number"
                                />
                            </td>
                            <td>
                                <Button variant={"success"} onClick={savePayoutRate}>
                                    Save
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>Chat Requests</td>
                            <td>{user?.chatRequestsReceived?.length}</td>
                        </tr>
                        <tr>
                            <td>Actions</td>
                            <td>
                                <Button variant={user.enabled ? "danger" : "success"} onClick={setEnabled}>
                                    {user.enabled ? "Disable" : "Enable"} User
                                </Button>
                            </td>
                        </tr>
                        </tbody>
                    </Table>

                    <h5>Chat sessions</h5>
                    <Table>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Session ID</th>
                            <th>Chat Duration</th>
                            <th>Chat Rate</th>
                            <th>Revenue</th>
                            <th>Psychic Share</th>
                            <th>Chat</th>
                        </tr>
                        </thead>
                        <tbody>
                        {/*Iterate over chatSessions object: */}
                        {Object.keys(allChatSessions).map((chatID) => {
                            const chatSession = allChatSessions[chatID];
                            return (
                                <tr key={chatSession.id}>
                                    <td>{moment(chatSession.created_at).format("MM-DD-YYYY HH:MM:SS")}</td>

                                    <td>
                                        {chatSession.chatSessionID}
                                    </td>
                                    <td>
                                        {chatSession.active_time} seconds
                                    </td>
                                    <td>
                                        ${(chatSession.chat_rate * 0.01).toFixed(2)}
                                    </td>
                                    <td>
                                        ${((chatSession.chat_rate * 0.01) * (chatSession.active_time / 60)).toFixed(2)}
                                    </td>
                                    <td>
                                        ${(chatSession.chat_rate * (chatSession.active_time / 60) * user.payout_rate * 0.01).toFixed(2)}
                                    </td>
                                    <td>
                                        <Link to={`/chat/${chatSession.chatID}`}>
                                            {chatSession.chatID}
                                        </Link>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>

                    <h5>Transfers</h5>
                    <Table>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Transfer Amount</th>
                            <th>Status</th>
                            <th>Customer Charge</th>
                            <th>Stripe balance on payout</th>
                            <th>Session ID</th>
                            <th>Chat</th>
                        </tr>
                        </thead>
                        <tbody>
                        {user?.transfers?.map((transfer) => {
                            return (
                                <tr key={transfer.transferID}>
                                    <td>{moment(transfer.created_at).format("MM-DD-YYYY HH:MM:SS")}</td>
                                    <td>${(transfer.amount / 100).toFixed(2)}</td>
                                    <td>{transfer.status}</td>
                                    <td>${transfer.source_charge_amount == -1 ? "CREDIT" : transfer.source_charge_amount * 0.01}</td>
                                    <td>${transfer.stripe_balance}</td>
                                    <td>
                                        {transfer && transfer.chatSession && (
                                            <div>
                                                {transfer.chatSession.chatSessionID}
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {transfer && transfer.chatSession && (
                                            <Link to={`/chat/${transfer.chatSession.chatID}`}>
                                                {transfer.chatSession.chatID}
                                            </Link>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </React.Fragment>
            )}
        </div>
    );
}
